<template>
  <div>
    <div class="row">
      <div class="col-xl-12 d-flex justify-content-end gutter-b">
        <router-link
          :to="{ name: 'mealsystem_diners' }"
          class="btn btn-outline-primary btn-xxl mr-3"
        >
          {{ $t("button.back") }}
        </router-link>
        <span class="btn btn-success" @click.prevent="saveDiner" ref="save_btn">
          {{ $t("mealsystem.diners.button.save_diner") }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Section-->
            <div class="d-flex align-items-center mb-6">
              <!--begin::Pic-->
              <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                <span class="svg-icon svg-icon-primary svg-icon-xxl">
                  <inline-svg
                    class="svg-icon"
                    src="/media/svg/icons/Communication/Address-card.svg"
                  />
                </span>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="d-flex flex-column mr-auto">
                <!--begin: Title-->
                <span
                  class="
                    card-title
                    font-weight-bolder font-size-h3
                    text-dark
                    mb-1
                  "
                  >{{ diner.first_name }} {{ diner.last_name }}</span
                >
                <span class="text-muted font-weight-bold">{{
                  diner.diner_number
                }}</span>
                <!--end::Title-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Section-->

            <ValidationObserver ref="form">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label" for="name">{{
                  $t("label.name")
                }}</label>
                <div class="col-lg-9 col-xl-3">
                  <ValidationProvider
                    name="name"
                    rules="required|min:3"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="diner.first_name"
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      v-bind:placeholder="$t('placeholder.name')"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-lg-9 col-xl-4">
                  <ValidationProvider
                    name="name"
                    rules="required|min:3"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="diner.last_name"
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      v-bind:placeholder="$t('placeholder.surname')"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label" for="diner_number">{{
                  $t("mealsystem.diners.label.diner_number")
                }}</label>
                <div class="col-lg-9 col-xl-7">
                  <ValidationProvider
                    name="name"
                    rules="required|min:3"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="diner.diner_number"
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      v-bind:placeholder="$t('mealsystem.diners.placeholder.diner_number')"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label" for="phone">{{
                  $t("label.born_date")
                }}</label>
                <div class="col-lg-9 col-xl-7">
                  <ValidationProvider rules="min:8" v-slot="{ errors }">
                    <div class="input-group input-group-lg input-group-solid">
                      <date-picker
                        v-model="diner.born_date"
                        type="date"
                        format="DD.MM.YYYY"
                        value-type="YYYY-MM-DD"
                      ></date-picker>
                    </div>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label" for="name">{{
                  $t("mealsystem.diners.label.category")
                }}</label>
                <div class="col-lg-9 col-xl-7">
                  <vs-select
                    v-if="categories"
                    v-model="diner.category.diners_category_id"
                    :options="categories"
                    :placeholder="
                      $t('mealsystem.placeholder.search_categories')
                    "
                    label="name"
                    :reduce="(categories) => categories.diners_category_id"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label" for="name">{{
                  $t("mealsystem.diners.label.department")
                }}</label>
                <div class="col-lg-9 col-xl-7">
                  <vs-select
                    v-if="departments"
                    v-model="diner.department.department_id"
                    :options="departments"
                    :placeholder="
                      $t('mealsystem.placeholder.search_departments')
                    "
                    label="name"
                    :reduce="(departments) => departments.department_id"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label" for="name">{{
                  $t("mealsystem.diners.label.discarted")
                }}</label>
                <div class="col-lg-9 col-xl-7 d-flex align-items-center">
                  <b-form-checkbox
                    v-model="diner.discarded"
                    name="discarded"
                    switch
                    class="d-inline mt-2"
                    value="1"
                    unchecked-value="0"
                    >{{
                      $t("mealsystem.diners.placeholder.discarted")
                    }}</b-form-checkbox
                  >
                </div>
              </div>
            </ValidationObserver>
          </div>
          <!--end::Body-->
        </div>

        <!--end::Card-->
      </div>
      <div class="col-xl-6">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Section-->
            <div class="">
              <h4
                class="
                  text-success
                  font-weight-bolder font-size-h1
                  d-block
                  text-center
                "
              >
                {{ diner.amount }},-
              </h4>
              <span class="text-center d-block text-muted mt-2">{{
                $t("mealsystem.diners.title.balance")
              }}</span>

              <div class="d-flex justify-content-between my-7">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="diner.account_to_minus"
                    name="account_to_minus"
                    switch
                    size="l"
                    class="d-inline"
                    value="1"
                    unchecked-value="0"
                  >
                    {{ $t("mealsystem.diners.label.allow_to_minus") }}
                  </b-form-checkbox>
                </div>
                <div class="d-flex">
                  <div class="mr-5">
                    <span
                      class="btn btn-danger"
                      @click.prevent="setContoType('WITHDRAW')"
                      v-b-modal.modal-conto-edit
                    >
                      <span class="svg-icon svg-icon-md svg-icon-white">
                        <inline-svg
                          src="/media/svg/icons/Navigation/Minus.svg"
                        />
                      </span>
                      {{ $t("mealsystem.diners.button.withdraw_conto") }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="btn btn-success"
                      @click.prevent="setContoType('DEPOSIT')"
                      v-b-modal.modal-conto-edit
                    >
                      <span class="svg-icon svg-icon-md svg-icon-white">
                        <inline-svg
                          src="/media/svg/icons/Navigation/Plus.svg"
                        />
                      </span>
                      {{ $t("mealsystem.diners.button.fill_conto") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="separator separator-solid"></div>

              <!--begin::Section-->
              <div class="d-flex align-items-center mt-5">
                <!--begin::Pic-->
                <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                  <span class="svg-icon svg-icon-primary svg-icon-xxl">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Code/Time-schedule.svg"
                    />
                  </span>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="d-flex justify-content-between w-100">
                  <!--begin: Title-->
                  <div>
                    <span
                      class="
                        card-title
                        font-weight-bolder font-size-h5
                        text-dark
                        mb-1
                      "
                      >{{
                        $t("mealsystem.diners.title.transaction_log_history")
                      }}</span>
                    </div>
                  <!--end::Title-->
                  <div>
                    <router-link
                      :to="{ name: 'mealsystem_billing_transactions' }"
                      class="btn btn-outline-primary py-0 pull-right"
                    >
                      {{ $t("mealsystem.diners.button.show_all") }}
                    </router-link>
                  </div>
                </div>
                <!--end::Info-->
              </div>
              <!--end::Section-->

              <perfect-scrollbar
                v-if="diner.transfer_logs.length"
                class="navi navi-hover scroll mt-5"
                style="min-height: 150px; max-height: 150px; position: relative"
              >
                <div class="list list-hover">
                  <div>
                    <div
                      v-for="(transfer_log, index) in diner.transfer_logs"
                      :key="index"
                      class="
                        d-flex
                        align-items-start
                        list-item
                        card-spacer-x
                        py-2
                      "
                    >
                      <!--begin::Info-->
                      <div class="flex-grow-1 mr-2 flex-wrap">
                        <span>
                          <span 
                            class="far fa-file-pdf mr-5 text-danger"
                            v-b-tooltip.left='$t("mealsystem.diners.title.download_document")'
                            @click.prevent="downloadDocument(transfer_log.transfer_id)"
                          ></span>
                        </span>
                        <strong
                          v-if="transfer_log.type == 'DEPOSIT'"
                          class="text-success d-inline mr-3"
                          >+ {{ transfer_log.amount }}</strong
                        >
                        <strong
                          v-if="transfer_log.type == 'WITHDRAW'"
                          class="text-danger d-inline mr-3"
                          >- {{ transfer_log.amount }}</strong
                        >
                        <span class="font-weight-bold text-muted"
                        v-if="transfer_log.type == 'DEPOSIT'"
                          >({{ transfer_log.kind_of_payment }})</span
                        >
                      </div>
                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-end
                          flex-wrap
                        "
                      >
                        <div>
                          <i class="far fa-user icon-sm mr-1"></i
                          >{{ transfer_log.user.full_name }}
                        </div>
                        <div class="font-weight-bolder ml-10">
                          <i class="far fa-calendar-alt icon-sm mr-1"></i>
                          {{ transfer_log.created_at | moment("D. M. YYYY HH:MM") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
              <div
                v-else
                class="
                  text-muted
                  d-flex
                  align-items-center
                  justify-content-center
                "
                style="height: 165px"
              >
                {{ $t("mealsystem.diners.title.diner_no_tansaction_history") }}
              </div>
            </div>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body d-flex justify-content-between my-5 py-0">
            <div>
              <!--begin::Section-->
              <div class="d-flex align-items-center">
                <!--begin::Pic-->
                <div class="flex-shrink-0 mr-8 symbol symbol-50 symbol-circle">
                  <span class="svg-icon svg-icon-primary svg-icon-xxl">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Shopping/Credit-card.svg"
                    />
                  </span>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="d-flex flex-column mr-auto" v-if="diner.card">
                  <!--begin: Title-->
                  <span
                    class="
                      card-title
                      font-weight-bolder font-size-h3
                      text-dark
                      mb-1
                    "
                    >{{ diner.card.card_number }}</span
                  >
                  <span class="text-muted font-weight-bold">{{
                    diner.card.hex_code
                  }}</span>
                  <!--end::Title-->
                </div>
                <div class="d-flex flex-column mr-auto" v-else>
                  <!--begin: Title-->
                  <span class="text-muted font-weight-bold py-5"
                    >{{ $t("mealsystem.diners.title.diner_no_assigned_card") }}
                  </span>
                  <!--end::Title-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Section-->
            </div>

            <div class="d-flex" v-if="diner.card">
              <div class="mr-5 mt-2">
                <span class="btn btn-danger" v-b-modal.modal-card-unpair>
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                  </span>
                  {{ $t("mealsystem.diners.button.unpair_card") }}
                </span>
              </div>
              <div class="mt-2">
                <span
                  class="btn btn-success"
                  v-b-modal.modal-card-edit
                >
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("mealsystem.diners.button.change_card") }}
                </span>
              </div>
            </div>
            <div class="d-flex" v-else>
              <div>
                <span
                  class="btn btn-success mt-2"
                  v-b-modal.modal-card-edit
                >
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("mealsystem.diners.button.assign_card") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <!--begin::Card-->
        <div class="card card-custom gutter-b card-stretch">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Section-->
            <div class="d-flex align-items-top">
              <!--begin::Section-->
              <div class="d-flex align-items-top w-100">
                <!--begin::Pic-->
                <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                  <span class="svg-icon svg-icon-primary svg-icon-xxl">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Code/Time-schedule.svg"
                    />
                  </span>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="d-flex justify-content-between mr-auto w-100">
                  <!--begin: Title-->
                  <div>
                    <span
                      class="
                        card-title
                        font-weight-bolder font-size-h5
                        text-dark
                        mb-1
                      "
                      >{{ $t("mealsystem.diners.title.orders_history") }}</span
                    >
                  </div>
                  <div>
                    <router-link
                      :to="{ name: 'mealsystem_diners_orders' }"
                      class="btn btn-outline-primary py-0 pull-right"
                    >
                      {{ $t("mealsystem.diners.button.show_all") }}
                    </router-link>
                  </div>
                  <!--end::Title-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Section-->
            </div>

            <perfect-scrollbar
              class="navi navi-hover scroll mt-5"
              style="max-height: 300px; position: relative"
            >
             
                  <table class="table table-hover">
                    <thead>
                        <tr>
                          <th>{{ $t("mealsystem.diners.title.orders_on_day") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_kind") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_code") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_meal_name") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_portion") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_price") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_source") }}</th>
                          <th>{{ $t("mealsystem.diners.title.orders_ordered") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(order, index) in diner.orders" :key="index">
                        <td>
                          <span>14.7.2021</span>
                        </td>
                        <td>
                          <span>Oběd</span>
                        </td>
                        <td>
                          <span>9</span>
                        </td>
                        <td>
                          <span>Polévka ruský borč, Jáhlová kaše, Kuře na paprice</span>
                        </td>
                        <td>
                          <span>1x</span>
                        </td>
                        <td>
                          <span>31,-</span>
                        </td>
                         <td>
                          <span>Internet</span>
                        </td>
                        <td>
                            <i class="far fa-calendar-alt icon-sm mr-1"></i> {{ order.created_at | moment("D. M. YYYY HH:MM") }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
            
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-card-unpair"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="$t('mealsystem.diners.title.confirm_unpair_card_title')"
    >
      <span
        v-html="$t('mealsystem.diners.title.confirm_unpair_card_text')"
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="unpairCard()">
          {{ $t("mealsystem.diners.button.delete_card") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-conto-edit"
      okVariant="success"
      :title="$t('mealsystem.diners.title.change_conto_balance')"
    >
      <ValidationObserver ref="conto_form">
        <ValidationProvider
          name="name"
          rules="required|min:1"
          v-slot="{ errors }"
        >
          <span class="text-muted mb-2 d-block">{{
            $t("mealsystem.diners.label.amount")
          }}</span>
          <input
            type="text"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('mealsystem.diners.placeholder.amount')"
            v-model="newContoTransfer.amount"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider
          name="name"
          rules="required|min:1"
          v-slot="{ errors }"
        >
          <span class="mt-5 text-muted mb-2 d-block">{{
            $t("mealsystem.diners.label.conto_type")
          }}</span>
          <vs-select
            v-model="newContoTransfer.type"
            :options="types_options"
            :placeholder="$t('mealsystem.diners.placeholder.types')"
            :clearable="false"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider
          name="name"
          rules="required|min:1"
          v-slot="{ errors }"
        >
          <span class="mt-5 text-muted mb-2 d-block">{{
            $t("mealsystem.diners.label.conto_kind_of_payment")
          }}</span>
          <vs-select
            v-model="newContoTransfer.conto_kind_of_payment"
            :options="payment_kinds_options"
            :placeholder="$t('mealsystem.diners.placeholder.kind_od_payments')"
            :clearable="false"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          variant="danger"
          @click="changeContoBalance()"
          v-if="newContoTransfer.type == 'WITHDRAW'"
        >
          {{ $t("mealsystem.diners.button.withdraw_conto") }}
        </b-button>
        <b-button
          variant="success"
          @click="changeContoBalance()"
          v-if="newContoTransfer.type == 'DEPOSIT'"
        >
          {{ $t("mealsystem.diners.button.fill_conto") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-card-edit"
      okVariant="success"
      :title="$t('mealsystem.diners.title.change_card')"
    >
      <ValidationObserver ref="card_form">
        <ValidationProvider
          name="name"
          rules="required|min:1"
          v-slot="{ errors }"
        >
          <span class="text-muted mb-2 d-block">{{
            $t("mealsystem.diners.label.search_card")
          }}</span>
          <vs-select
            :options="cards"
            v-model="newCardAssign"
            :placeholder="$t('mealsystem.diners.placeholder.cards')"
            :clearable="false"
            label="card_number" 
            :reduce="(cards) => cards.card_id"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="assignCard()">
          {{ $t("mealsystem.diners.button.assign_card") }}
        </b-button>
      </template>
    </b-modal>


  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
// import { Base64 } from 'js-base64';

export default {
  components: {},
  data() {
    return {
      diner: null,
      departments: null,
      categories: null,
      cards: null,
      diner_transfer_logs: null,
      diner_order_logs: null,
      newContoTransfer: {
        type: null,
      },
      newCardAssign: {},
      types_options: ["DEPOSIT", "WITHDRAW"],
      payment_kinds_options: ["CASH", "TRANSFER"],
    };
  },

  metaInfo() {
    return { title: this.$t("mealsystem.meta.diners_detail") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.diners"),
        subtitle: this.$t("mealsystem.meta.diners_detail"),
      },
    ]);
    this.getDinerDetail();
    this.getCategories();
    this.getDepartments();
    this.getCards();
  },
  methods: {
    getDinerDetail() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/diners/" + this.$route.params.id).then(
        (response) => {
          this.diner = response.data.data;
          this.loading = false;
        }
      );
    },
    getCategories() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/diners/categories").then((response) => {
        this.categories = response.data.data;
        this.loading = false;
      });
    },
    getDepartments() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/departments").then((response) => {
        this.departments = response.data.data;
        this.loading = false;
      });
    },
    getCards() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/diners/cards").then((response) => {
        this.cards = response.data.data;
        this.loading = false;
      });
    },
    unpairCard() {
      this.loading = true;
      ApiService.apiPost(
        "/meal-system/diners/cards/" + this.diner.card.card_id + "/assignment",
        {
          diner_number: null,
        }
      )
        .then(() => {
          this.loading = false;
          this.$bvModal.hide('modal-card-unpair');
          this.getDinerDetail();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    setContoType(conto_transfer_type) {
      this.newContoTransfer = {};
      this.newContoTransfer.type = conto_transfer_type;
    },
    changeContoBalance() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        }

        ApiService.apiPost(
        "/meal-system/diners/" + this.$route.params.id + "/change-amount",
        {
          amount: this.newContoTransfer.amount,
          type: this.newContoTransfer.type,
          kind_of_payment: this.newContoTransfer.conto_kind_of_payment,
        }
      )
        .then(() => {
          this.loading = false;
          this.$bvModal.hide('modal-conto-edit');
          this.getDinerDetail();
        })
        .catch((error) => {
          checkErrors(error);
        });
      });
    },
    downloadDocument(){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/test/generatePdf ").then((response) => {
      
        const link = document.createElement('a');
        link.href = 'data:application/pdf;base64,' + response.data.data;
        link.setAttribute('download', 'Doklad.pdf');
        document.body.appendChild(link);
        link.click();

        this.loading = false;
      });
    },
    assignCard(){
      this.loading = true;
      ApiService.apiPost(
        "/meal-system/diners/cards/" + this.newCardAssign + "/assignment",
        {
          diner_number: this.diner.diner_number,
        }
      )
        .then(() => {
          this.loading = false;
          this.$bvModal.hide('modal-card-edit');
          this.getDinerDetail();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    saveDiner() {
      // Loading spinner
      const submitButton = this.$refs["save_btn"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
      ApiService.apiPost(
        "/meal-system/diners/" + this.$route.params.id + "/update",
        {
          first_name: this.diner.first_name,
          last_name: this.diner.last_name,
          diner_number: this.diner.diner_number,
          born_date: this.diner.born_date,
          diners_category_id: this.diner.category.diners_category_id,
          department_id: this.diner.department.department_id,
          account_to_minus: this.diner.account_to_minus,
          discarded: this.diner.discarded
        }
      )
        .then(() => {
          this.loading = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.getDinerDetail();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
  },
};
</script>

